import actions from "./actions";

const initialState = {
    data: [],
    loading: false,
    error: null,
    create: {
        data: {},
        loading: false,
        error: null,
        success: false,
    },
    update: {
        data: {},
        loading: false,
        error: null,
        success: false,
    },
    updatePassword: {
        data: {},
        loading: false,
        error: null,
        success: false,
    }
};
const {
    USERS_DATA_BEGIN,
    USERS_DATA_SUCCESS,
    USERS_DATA_ERR,
    USERS_CREATE_BEGIN,
    USERS_CREATE_REQUEST,
    USERS_CREATE_SUCCESS,
    USERS_CREATE_ERR,
    USER_UPDATE_REQUEST,
    USER_UPDATE_SUCCESS,
    USER_UPDATE_ERR,
} = actions;
const userReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case USERS_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case USERS_DATA_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case USERS_DATA_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };

        case USERS_CREATE_BEGIN:
            return {
                ...state,
                create: {
                    ...state.create,
                    data
                },
            };
        case USERS_CREATE_REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                    success: false,
                    error: null,
                },
            };
        case USERS_CREATE_SUCCESS:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: true,
                    error: null,
                },
            };
        case USERS_CREATE_ERR:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    success: false,
                    error: err,
                },
            };
        case USER_UPDATE_REQUEST:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: true,
                    success: false,
                    error: null,
                },
            };
        case USER_UPDATE_SUCCESS:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    success: true,
                    error: null,
                    data: data
                },
            };
        case USER_UPDATE_ERR:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    success: false,
                    error: err,
                },
            };
        default:
            return state;
    }
};

export { userReducer };
