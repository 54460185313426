import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};
const { ORGANIZATIONS_DATA_BEGIN, ORGANIZATIONS_DATA_SUCCESS, ORGANIZATIONS_DATA_ERR } = actions;
const organizationsReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ORGANIZATIONS_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case ORGANIZATIONS_DATA_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case ORGANIZATIONS_DATA_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export { organizationsReducer };
