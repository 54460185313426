import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,
};
const { SOURCE_DATA_BEGIN, SOURCE_DATA_SUCCESS, SOURCE_DATA_ERR } = actions;
const sourcesReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case SOURCE_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case SOURCE_DATA_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case SOURCE_DATA_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export { sourcesReducer };
