import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import ModifyData from '../../container/dataEntry/modify_data';

const UploadData = lazy(() => import('../../container/dataEntry/upload/'));


function DataEntry() {
    return (
        <Routes>
            <Route path="upload-data" element={<UploadData />} />
            <Route path="modify-data" element={<ModifyData />} />
        </Routes>
    );
}

export default DataEntry;
