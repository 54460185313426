import axios from "axios";
import { getItem } from "./localStorageControl";

/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length ? `${'0'.repeat(length - number.length)}${number}` : number;
};


function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

export { ellipsis, idGenerator, kFormatter, searchObjects, downloadFile, delay };


function searchObjects(searchTerm, array) {
  const results = [];

  for (let i = 0; i < array.length; i++) {
    const object = array[i];

    for (let key in object) {
      if (object.hasOwnProperty(key)) {
        const value = object[key];
        if (value !== null && typeof value !== 'number' && String(value).toLowerCase().includes(searchTerm.toLowerCase())) {
          results.push(object);
          break;
        }
      }
    }
  }

  return results;
}



async function downloadFile(url, fileName) {
  const downloadUrl = url;

  axios.get(downloadUrl, {
    responseType: 'blob',
  })
    .then((response) => {
      console.log(response)
      // Create a download link
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();
    })
    .catch((error) => {
      console.error('Error downloading file:', error);
    });
}

function delay(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}