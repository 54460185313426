import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Form, Input, Button, message } from "antd";

import { useDispatch, useSelector } from "react-redux";
import { AuthFormWrap } from "./style";
import { register } from "../../../../redux/authentication/actionCreator";

function EnterKeywords() {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);

	function handleSubmit(values) {
		const data = {
			...authState.singUpValues,
			brand_keywords: values.brand_keywords.split(","),
			competitor_keywords: values.competitor_keywords.split(","),
		};
		dispatch(
			register(
				data,
				() => {
					message.success(`You have successfully created an account`);
					setTimeout(() => {
						window.location.replace("/");
					}, [2000]);
				},
				(error) => {
					message.error(`An error occurred while creating account`);
				}
			)
		);
	}

	function getPackageMaxKeywordCount() {
		switch (authState?.singUpValues?.package_id) {
			case 1:
				return 2;
			case 2:
				return 5;
			case 3:
				return 12;
			case 4:
				return 20;
			default:
				return 2;
		}
	}

	return (
		<Row justify="center">
			<Col xxl={6} xl={8} md={12} sm={18} xs={24}>
				<AuthFormWrap className="mt-6 bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
					<div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
						<h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">
							Sign Up
						</h2>
					</div>
					<div className="px-10 pt-8 pb-6">
						<Form
							name="register"
							onFinish={handleSubmit}
							layout="vertical"
						>
							<Form.Item
								label="Brand Keyword(s)"
								name="brand_keywords"
								normalize={(value) => {
									const inputValues = value.trim().split(",");
									const inputLength = inputValues.length;
									console.log("zehaha");
									if (
										inputLength >
										getPackageMaxKeywordCount()
									) {
										inputValues.pop();
										message.error(
											"Your can only enter " +
												getPackageMaxKeywordCount() +
												" keywords for your package"
										);
										return inputValues.join(",");
									}

									return value;
								}}
								/* Other props */

								className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
								rules={[
									{
										required: true,
										message:
											"Please input your brand's keyword(s)",
									},
								]}
							>
								<Input
									// value={state.brand_keywords}
									placeholder="Separated by commas(,)"
								/>
							</Form.Item>
							<Form.Item
								label="Competitor Keyword(s)"
								name="competitor_keywords"
								normalize={(value) => {
									const inputValues = value.trim().split(",");
									const inputLength = inputValues.length;
									if (
										inputLength >
										getPackageMaxKeywordCount()
									) {
										inputValues.pop();
										message.error(
											"Your can only enter " +
												getPackageMaxKeywordCount() +
												" keywords for your package"
										);
										return inputValues.join(",");
									}

									return value;
								}}
								className="[&>div>div>label]:text-sm [&>div>div>label]:text-dark dark:[&>div>div>label]:text-white60 [&>div>div>label]:font-medium"
								rules={[
									{
										required: true,
										message:
											"Please input your competitor's keyword(s)",
									},
								]}
							>
								<Input placeholder="Seperated by commas(,)" />
							</Form.Item>
							{/* <div className="flex items-center justify-between">
                <Checkbox onChange={onChange} checked={state.checked}>
                  Creating an account means you’re okay with our Terms of Service and Privacy Policy
                </Checkbox>
              </div> */}
							<Form.Item>
								<Button
									className="w-full h-12 p-0 my-6 text-sm font-medium"
									htmlType="submit"
									type="primary"
									size="large"
								>
									Make Payment
								</Button>
							</Form.Item>
						</Form>
					</div>
					<div className="p-6 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
						<p className="mb-0 text-sm font-medium text-body dark:text-white60">
							Already have an account?
							<Link
								to="/"
								className="ltr:ml-1.5 rtl:mr-1.5 text-info hover:text-primary"
							>
								Sign In
							</Link>
						</p>
					</div>
				</AuthFormWrap>
			</Col>
		</Row>
	);
}

export default EnterKeywords;
