import Cookies from "js-cookie";
import actions from "./actions";
import { DataService } from "../../config/dataService/dataService";
import { setItem } from "../../utility/localStorageControl";

const {
	loginBegin,
	loginSuccess,
	loginErr,
	logoutBegin,
	logoutSuccess,
	logoutErr,
	setSignUpValues,
} = actions;

const login = (values, callback) => {
	return async (dispatch) => {
		dispatch(loginBegin());
		try {
			const response = await DataService.post("/login", values);
			if (response.data.errors) {
				dispatch(loginErr(response.data.errors));
			} else {
				Cookies.set("token", response.data.data.token);
				Cookies.set("loggedIn", true);
				setItem("user", response.data.data);
				dispatch(loginSuccess(true));
				callback();
			}
		} catch (err) {
			dispatch(loginErr(err));
		}
	};
};

const doSetSigUpValues = (data) => {
	return async (dispatch) => {
		dispatch(setSignUpValues(data));
	};
};

const register = (values, callback, onError) => {
	return async (dispatch) => {
		dispatch(loginBegin());
		try {
			const response = await DataService.post("/register", values);
			if (response.data.errors) {
				dispatch(loginErr("Registration failed!"));
			} else {
				dispatch(loginSuccess(false));
				callback();
			}
		} catch (err) {
			onError(err);
			dispatch(loginErr(err));
		}
	};
};

const logOut = (callback) => {
	return async (dispatch) => {
		dispatch(logoutBegin());
		try {
			var cookies = document.cookie.split(";");

			for (var i = 0; i < cookies.length; i++) {
				var cookie = cookies[i];
				var eqPos = cookie.indexOf("=");
				var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie =
					name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
			}
			dispatch(logoutSuccess(false));
			callback();
		} catch (err) {
			dispatch(logoutErr(err));
		}
	};
};

export { login, logOut, register, doSetSigUpValues };
