import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Settings = lazy(() => import('../../container/profile/settings/Settings'));


function ManageRoutes() {
    return (
        <Routes>
            <Route path="/*" element={<Settings />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default ManageRoutes;
