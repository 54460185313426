import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";

const NotFound = lazy(() => import("../../container/pages/404"));
const PdfReports = lazy(() => import("../../container/reports/pdf"));
const ExcelReports = lazy(() => import("../../container/reports/excel"));
const InfographicReports = lazy(() => import("../../container/reports/jpeg"));

function ReportRoutes() {
	return (
		<Routes>
			<Route path="pdf" element={<PdfReports />} />
			<Route path="excel" element={<ExcelReports />} />
			<Route path="infographic" element={<PdfReports />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}

export default ReportRoutes;
