
const actions = {
    SOURCE_DATA_BEGIN: 'SOURCE_DATA_BEGIN',
    SOURCE_DATA_SUCCESS: 'SOURCE_DATA_SUCCESS',
    SOURCE_DATA_ERR: 'SOURCE_DATA_ERR',

    sourcesDataBegin: () => {
        return {
            type: actions.SOURCE_DATA_BEGIN,
        };
    },

    sourcesDataSuccess: (data) => {
        return {
            type: actions.SOURCE_DATA_SUCCESS,
            data,
        };
    },

    sourcesDataErr: (err) => {
        return {
            type: actions.SOURCE_DATA_ERR,
            err,
        };
    },
};

export default actions