import {
	UilChart,
	UilDatabase,
	UilMegaphone,
	UilBuilding,
	UilPen,
	// UilBell,
	// UilUsersAlt,
	UilFileGraph,
	UilComparison,
} from "@iconscout/react-unicons";
import { Menu } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import UilEllipsisV from "@iconscout/react-unicons/icons/uil-ellipsis-v";
import propTypes from "prop-types";
import { NavTitle } from "./Style";
// import versions from "../demoData/changelog.json";
// import {
// 	changeDirectionMode,
// 	changeLayoutMode,
// 	changeMenuMode,
// } from "../redux/themeLayout/actionCreator";
import { getItem as getCookie } from "../utility/localStorageControl";
function MenuItems({ toggleCollapsed }) {
	const { t } = useTranslation();
	const authInfo = getCookie("user");
	console.log(authInfo);
	function getItem(label, key, icon, children, type) {
		return {
			key,
			icon,
			children,
			label,
			type,
		};
	}

	const { topMenu } = useSelector((state) => {
		return {
			topMenu: state.ChangeLayoutMode.topMenu,
		};
	});

	// const dispatch = useDispatch();

	const path = "/admin";

	const pathName = window.location.pathname;
	const pathArray = pathName.split(path);
	const mainPath = pathArray[1];
	const mainPathSplit = mainPath.split("/");

	const [openKeys, setOpenKeys] = React.useState(
		!topMenu
			? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`]
			: []
	);

	const onOpenChange = (keys) => {
		setOpenKeys(
			keys[keys.length - 1] !== "recharts"
				? [keys.length && keys[keys.length - 1]]
				: keys
		);
	};

	const onClick = (item) => {
		if (item.keyPath.length === 1) setOpenKeys([]);
	};

	// const changeLayout = (mode) => {
	// 	dispatch(changeLayoutMode(mode));
	// };
	// const changeNavbar = (topMode) => {
	// 	const html = document.querySelector("html");
	// 	if (topMode) {
	// 		html.classList.add("hexadash-topmenu");
	// 	} else {
	// 		html.classList.remove("hexadash-topmenu");
	// 	}
	// 	dispatch(changeMenuMode(topMode));
	// };
	// const changeLayoutDirection = (rtlMode) => {
	// 	if (rtlMode) {
	// 		const html = document.querySelector("html");
	// 		html.setAttribute("dir", "rtl");
	// 	} else {
	// 		const html = document.querySelector("html");
	// 		html.setAttribute("dir", "ltr");
	// 	}
	// 	dispatch(changeDirectionMode(rtlMode));
	// };

	// const darkmodeActivated = () => {
	// 	document.body.classList.add("dark");
	// };

	// const darkmodeDeactivated = () => {
	// 	document.body.classList.remove("dark");
	// };

	const items = [
		getItem(
			<NavLink onClick={toggleCollapsed} to={`${path}/`}>
				{t("dashboard")}
			</NavLink>,
			"dashboard",
			!topMenu && <UilChart />
		),

		getItem(
			!topMenu && (
				<NavTitle className="flex text-[12px] font-medium uppercase text-light mt-[20px] dark:text-white87 p-0">
					{t("manage")}
				</NavTitle>
			),
			"app-title",
			null,
			null,
			"group"
		),
		(authInfo?.usertype === "is_customer_service" ||
			authInfo?.usertype === "is_admin") &&
		getItem(
			<NavLink onClick={toggleCollapsed} to={`${path}/organizations`}>
				{t("organizations")}
			</NavLink>,
			"organization",
			<UilBuilding />
		),
		// getItem(
		//   <NavLink onClick={toggleCollapsed} to={`${path}/users`}>
		//     {t('users')}
		//   </NavLink>,
		//   'users',
		//   <UilUsersAlt />,
		// ),
		(authInfo?.usertype === "is_data_entry" ||
			authInfo?.usertype === "is_admin") &&
		getItem("Data entry", "data-entry", <UilPen />, [
			getItem(
				<NavLink
					onClick={toggleCollapsed}
					to={`${path}/data-entry/upload-data`}
				>
					{t("upload")} {t("data")}
				</NavLink>,
				"upload-data",
				null
			),
			getItem(
				<NavLink
					onClick={toggleCollapsed}
					to={`${path}/data-entry/modify-data`}
				>
					{t("modify")} {t("data")}
				</NavLink>,
				"modify-data",
				null
			),
		]),
		(authInfo?.usertype === "is_admin" ||
			authInfo?.usertype === "is_customer_service" ||
			(authInfo?.usertype === "is_client" &&
				authInfo?.package.package_id >= 3)) &&
		getItem(
			<NavLink onClick={toggleCollapsed} to={`${path}/sources`}>
				{t("sources")}
			</NavLink>,
			"sources",
			<UilDatabase />
		),
		(authInfo?.usertype === "is_admin" ||
			authInfo?.usertype === "is_customer_service" ||
			authInfo?.usertype === "is_client") &&
		getItem(
			<NavLink onClick={toggleCollapsed} to={`${path}/ads`}>
				{t("mentions")}
			</NavLink>,
			"mentions",
			<UilMegaphone />
		),
		(authInfo?.usertype === "is_admin" ||
			(authInfo?.usertype === "is_client" &&
				authInfo?.package?.package_id >= 3)) &&
		getItem(
			<NavLink onClick={toggleCollapsed} to={`${path}/comparisons`}>
				{t("comparisons")}
			</NavLink>,
			"comparisons",
			<UilComparison />
		),
		(authInfo?.usertype === "is_customer_service" ||
			authInfo?.usertype === "is_admin" ||
			authInfo?.usertype === "is_client") &&
		getItem("Reports", "reports", !topMenu && <UilFileGraph />, [
			// getItem(
			//   <NavLink onClick={toggleCollapsed} to={`${path}/email-reports`}>
			//     {t('email')}
			//     {t('reports')}
			//   </NavLink>,
			//   'email-reports',
			//   null,
			// ),
			getItem(
				<NavLink
					onClick={toggleCollapsed}
					to={`${path}/reports/pdf`}
				>
					{t("pdf")} {t("reports")}
				</NavLink>,
				"pdf-reports",
				null
			),
			(authInfo?.usertype === "is_customer_service" ||
				authInfo?.usertype === "is_admin" ||
				(authInfo?.usertype === "is_client" &&
					authInfo?.package?.package_id >= 2)) &&
			getItem(
				<NavLink
					onClick={toggleCollapsed}
					to={`${path}/reports/excel`}
				>
					{t("excel")} {t("reports")}
				</NavLink>,
				"excel-report",
				null
			),
			(authInfo?.usertype === "is_customer_service" ||
				authInfo?.usertype === "is_admin" ||
				(authInfo?.usertype === "is_client" &&
					authInfo?.package?.package_id >= 3)) &&
			getItem(
				<NavLink
					onClick={toggleCollapsed}
					to={`${path}/reports/infographic`}
				>
					{t("infographic")}
				</NavLink>,
				"infographic",
				null
			),
		]),
	];

	return (
		<Menu
			onOpenChange={onOpenChange}
			onClick={onClick}
			mode={
				!topMenu || window.innerWidth <= 991 ? "inline" : "horizontal"
			}
			// // eslint-disable-next-line no-nested-ternary
			defaultSelectedKeys={
				!topMenu
					? [
						`${mainPathSplit.length === 1
							? "home"
							: mainPathSplit.length === 2
								? mainPathSplit[1]
								: mainPathSplit[2]
						}`,
					]
					: []
			}
			defaultOpenKeys={
				!topMenu
					? [
						`${mainPathSplit.length > 2
							? mainPathSplit[1]
							: "dashboard"
						}`,
					]
					: []
			}
			overflowedIndicator={<UilEllipsisV />}
			openKeys={openKeys}
			items={items}
		/>
	);
}

MenuItems.propTypes = {
	toggleCollapsed: propTypes.func,
};

export default MenuItems;
