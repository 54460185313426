/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Table, Radio, Divider, message } from 'antd';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrash from '@iconscout/react-unicons/icons/uil-trash-alt';
import UilUser from '@iconscout/react-unicons/icons/uil-users-alt'
import { Link, useNavigate } from 'react-router-dom';
import { PageHeader } from '../../components/page-headers/page-headers';
import Heading from '../../components/heading/heading';
import DataTable from '../../components/table/OrganizationsTable';
import UserListTable from '../pages/overview/UserTable';
import ProjectLists from '../project/overview/List';
import TaskList from '../project/overview/TaskList';
import { GlobalUtilityStyle, PaginationStyle } from '../styled';
import { Skeleton } from 'antd'

import { fetchOrganizationsData } from '../../redux/organizations/actionCreators';
import { getItem } from '../../utility/localStorageControl';
import { DataService } from '../../config/dataService/dataService';
import { Modal } from '../../components/modals/antd-modals';

function Organizations() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [state, setState] = useState({
        selectionType: 'checkbox',
        selectedRowKeys: null,
        selectedRows: null,
        values: {},
        organizationToDeleteId: null,
        deleteModalVisible: false,
        admin: null
    });

    const PageRoutes = [
        {
            path: 'index',
            breadcrumbName: 'Dashboard',
        },
        {
            path: 'first',
            breadcrumbName: 'Organizations',
        },
    ];



    const handelOrganizationDelete = async () => {
        setState({
            ...state,
            deleteModalVisible: false,
            organizationToDeleteId: null,
        })
        const data = {
            uid: state.organizationToDeleteId,
            "updated_by": state.admin.full_name,
            "modifier_host_id": state.admin.client_id
        }
        const hide = message.loading('Deleting organization...');
        try {
            const response = await DataService.post('/users/delete-org', data)
            message.success("organization deleted successfully");
            hide();
            window.location.replace("/admin/organizations")

        }
        catch (err) {
            let errorMsg = 'An error occured while deleting organization'
            if (err.response) {
                errorMsg = err.response.data.message
            }
            hide();
            message.error(errorMsg)
        }

    }
    useEffect(() => {
        setState({
            ...state,
            admin: getItem('user')
        })
    }, [])

    useEffect(() => {
        if (dispatch) {
            dispatch(fetchOrganizationsData());
        }
    }, [dispatch]);

    const { TableData, loadingState } = useSelector((states) => {
        return {
            TableData: states.organizations.data,
            loadingState: states.organizations.loading,
        };
    });

    const onMediaFilterChange = (value) => {
        console.log(`selected ${value}`);
        if (dispatch) {
            dispatch(fetchOrganizationsData(() => { }, value));
        }
    }
    const tableDataScource = [];

    if (TableData.length > 0) {
        TableData.map((item) => {
            const { id, e_mail, uid, client_name, mobile_phone_1, organization, created_at } = item;
            return tableDataScource.push({
                id: <span className="text-body dark:text-white60 text-[15px] font-medium">{`#${id}`}</span>,
                organization: <span className="text-body dark:text-white60 text-[15px] font-medium">{client_name}</span>,
                email: <span className="text-body dark:text-white60 text-[15px] text-center font-medium">{e_mail}</span>,
                phone: <span className="text-body dark:text-white60 text-[15px] font-medium">{mobile_phone_1}</span>,
                created_at: <span className="text-body dark:text-white60 text-[15px] font-medium text-center">{created_at}</span>,
                // status: (
                //     <span
                //         className={`inline-flex items-center justify-center bg-${status}-transparent text-${status} min-h-[24px] px-3 text-xs font-medium rounded-[15px]`}
                //     >
                //         {status}
                //     </span>
                // ),
                action: (
                    <div className="min-w-[150px] text-end -m-2">
                        <Link to="#" className="inline-block m-2" >
                            <UilEye title={"View users"} className="w-5 text-light-extra dark:text-white60" />
                        </Link>
                        <Link title='View users' className="inline-block m-2" to={`/admin/users?organization=${client_name}`}>
                            <UilUser title={"View users"} className="w-5 text-light-extra dark:text-white60" />
                        </Link>
                        <Link className="inline-block m-2" to={`/admin/organizations/${uid}`}>
                            <UilEdit className="w-5  text-light-extra dark:text-white60" />
                        </Link>
                        <Link className="inline-block m-2" to="#" onClick={() => {
                            setState({
                                ...state,
                                deleteModalVisible: true,
                                organizationToDeleteId: uid
                            })
                        }}>

                            <UilTrash className="w-5 text-light-extra dark:text-white60" />
                        </Link>
                    </div>
                ),
            });
        });
    }
    else if (loadingState) {
        for (var i = 0; i < 10; i++) {
            tableDataScource.push({
                id: <Skeleton.Button active={true} style={{
                    height: "15px"
                }} />,

                organization: <Skeleton.Button active={true} style={{
                    height: "15px"
                }} />,
                email: <Skeleton.Button active={true} style={{
                    height: "15px"
                }} />,
                phone: <Skeleton.Button active={true} style={{
                    height: "15px"
                }} />,
                created_at: <Skeleton.Button active={true} style={{
                    height: "15px"
                }} />,
                action: (
                    <div className="min-w-[150px] text-end -m-2">
                        <Skeleton.Button className="inline-block m-2 w-4" />
                        <Skeleton.Button className="inline-block m-2 w-4" />
                        <Skeleton.Button className="inline-block m-2 w-4" />

                    </div>
                ),
            })
        }
    }

    const dataTableColumn = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Organization',
            dataIndex: 'organization',
            key: 'organization',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            key: 'phone',
        },

        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
        },

        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            width: '90px',
        },
    ];

    function onChange(pagination, filters, sorter, extra) {
        setState({ ...state, values: { pagination, filters, sorter, extra } });
    }
    const handleCancel = () => {
        setState({
            ...state,
            deleteModalVisible: false
        })
    }

    return (
        <>
            <Modal
                type={"danger"}
                title="Delete Organization"
                visible={state.deleteModalVisible}
                onOk={handelOrganizationDelete}
                onCancel={handleCancel}
            >
                <div className="dark:text-white60">
                    <p>Are you sure you want to delete this organization ?</p>

                </div>
            </Modal>
            <PageHeader
                routes={PageRoutes}
                title="Organizations"
                className="flex items-center justify-between px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
            />
            <div className="min-h-[715px] lg:min-h-[580px] flex-1 h-auto px-8 xl:px-[15px] pb-[30px] bg-transparent">
                <GlobalUtilityStyle>
                    <Row gutter={15}>
                        <Col xs={24} className="mb-[25px]">
                            <PaginationStyle>
                                <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
                                    <div className="py-[16px] px-[25px] text-dark dark:text-white87 font-medium text-[17px] border-regular dark:border-white10 border-b ">
                                        <Heading as="h4" className="text-lg font-medium mb-0">
                                            Organizations                                        </Heading>
                                    </div>
                                    <div className="p-[25px]">
                                        <DataTable
                                            filterOption
                                            filterOnchange

                                            onFilterChange={onMediaFilterChange}
                                            tableData={tableDataScource}
                                            columns={dataTableColumn}
                                            rowSelection={false}
                                        />
                                    </div>
                                </div>
                            </PaginationStyle>


                        </Col>

                    </Row>
                </GlobalUtilityStyle>
            </div>
        </>
    );
}

export default Organizations;
