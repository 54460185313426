
const actions = {
    USERS_DATA_BEGIN: 'USERS_DATA_BEGIN',
    USERS_DATA_SUCCESS: 'USERS_DATA_SUCCESS',
    USERS_DATA_ERR: 'USERS_DATA_ERR',

    USERS_CREATE_BEGIN: 'USERS_CREATE_BEGIN',
    USERS_CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    USERS_CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    USERS_CREATE_ERR: 'USERS_CREATE_ERR',
    USER_UPDATE_REQUEST: 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
    USER_UPDATE_ERR: 'USER_UPDATE_ERR',

    usersDataBegin: () => {
        return {
            type: actions.USERS_DATA_BEGIN,
        };
    },

    usersDataSuccess: (data) => {
        return {
            type: actions.USERS_DATA_SUCCESS,
            data,
        };
    },

    usersDataErr: (err) => {
        return {
            type: actions.USERS_DATA_ERR,
            err,
        };
    },

    usersCreateBegin: (data) => {
        return {
            type: actions.USERS_CREATE_BEGIN,
            data
        };
    },

    usersCreateRequest: (data) => {
        return {
            type: actions.USERS_CREATE_REQUEST,
            data,
        };
    },

    usersCreateSuccess: (data) => {
        return {
            type: actions.USERS_CREATE_SUCCESS,
            data,
        };
    },

    usersCreateErr: (err) => {
        return {
            type: actions.USERS_CREATE_ERR,
            err,
        };
    },
    userUpdateRequest: () => {
        return {
            type: actions.USER_UPDATE_REQUEST,
        };
    },

    userUpdateSuccess: (data) => {
        return {
            type: actions.USER_UPDATE_SUCCESS,
            data,
        };
    },

    userUpdateErr: (err) => {
        return {
            type: actions.USER_UPDATE_ERR,
            err,
        };
    },
};

export default actions