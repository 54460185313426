import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,

};
const { ADS_DATA_BEGIN, ADS_DATA_SUCCESS, ADS_DATA_ERR } = actions;
const adsReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case ADS_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case ADS_DATA_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case ADS_DATA_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export { adsReducer };
