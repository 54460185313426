/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Table, Radio, Divider, message } from "antd";
import UilEye from "@iconscout/react-unicons/icons/uil-eye";
import UilEdit from "@iconscout/react-unicons/icons/uil-edit";
import UilTrash from "@iconscout/react-unicons/icons/uil-trash-alt";
import UilFileDownload from "@iconscout/react-unicons/icons/uil-file-download";
import { Link } from "react-router-dom";
import { PageHeader } from "../../../components/page-headers/page-headers";
import Heading from "../../../components/heading/heading";
import DataTable from "../../../components/table/FilesTable";
import { GlobalUtilityStyle, PaginationStyle } from "../../styled";
import { Skeleton } from "antd";
import { Modal } from "../../../components/modals/antd-modals";

import { tableReadData } from "../../../redux/data-filter/actionCreator";
import {
	doDeleteFile,
	fetchSourcesData,
} from "../../../redux/sources/actionCreators";
import { fetchFilesData } from "../../../redux/files/actionCreators";
import { downloadFile } from "../../../utility/utility";
import fileDownload from "js-file-download";
function DataTables() {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		selectionType: "checkbox",
		selectedRowKeys: null,
		selectedRows: null,
		selectedFileToDeleteId: null,
		deleteModalVisible: false,
		values: {},
	});

	const PageRoutes = [
		{
			path: "index",
			breadcrumbName: "Dashboard",
		},
		{
			path: "first",
			breadcrumbName: "Files",
		},
	];

	useEffect(() => {
		if (dispatch) {
			dispatch(fetchFilesData());
		}
	}, [dispatch]);

	const handleDeleteFile = () => {
		setState({
			...state,
			deleteModalVisible: false,
			fileToDeleteId: null,
		});
		const hide = message.loading("Deleting file...");
		doDeleteFile(
			() => {
				hide();
				message.success("File deleted successfully");
				dispatch(fetchFilesData());
			},
			state.fileToDeleteId,
			(err) => {
				console.log(err);
				message.error("Error deleting file");
				hide();
			}
		);
	};

	const handleCancel = () => {
		setState({
			deleteModalVisible: true,
		});
	};

	const { TableData, loadingState } = useSelector((states) => {
		return {
			TableData: states.files.data,
			loadingState: states.files.loading,
		};
	});

	const onMediaFilterChange = (value) => {
		console.log(`selected ${value}`);
		if (dispatch) {
			dispatch(fetchSourcesData(() => {}, value));
		}
	};
	const tableDataScource = [];

	if (TableData.length > 0 && !loadingState) {
		TableData.map((item) => {
			const { id, file, created_at, download_url, created_by } = item;
			return tableDataScource.push({
				id: (
					<span className="text-body dark:text-white60 text-[15px] font-medium">{`#${id}`}</span>
				),
				file: (
					<span className="text-body dark:text-white60 text-[15px] font-medium">
						{file}
					</span>
				),
				created_by: (
					<span className="text-body dark:text-white60 text-[15px] font-medium">
						{created_by}
					</span>
				),
				created_at: (
					<span className="text-body dark:text-white60 text-[15px] font-medium text-center">
						{created_at}
					</span>
				),
				action: (
					<div className="min-w-[150px] text-end -m-2">
						<Link
							className="inline-block m-2"
							to={download_url ?? "#"}
							target="_blank"
						>
							<UilFileDownload className="w-6 text-light-extra dark:text-white60" />
						</Link>
						<Link
							className="inline-block m-2"
							to="#"
							onClick={() => {
								setState({
									...state,
									deleteModalVisible: true,
									fileToDeleteId: id,
								});
							}}
						>
							<UilTrash className="w-6 text-light-extra dark:text-white60" />
						</Link>
					</div>
				),
			});
		});
	} else if (loadingState) {
		for (var i = 0; i < 10; i++) {
			tableDataScource.push({
				id: (
					<Skeleton.Button
						active={true}
						style={{
							height: "15px",
						}}
					/>
				),

				file: (
					<Skeleton.Button
						active={true}
						style={{
							height: "15px",
						}}
					/>
				),
				created_by: (
					<Skeleton.Button
						active={true}
						style={{
							height: "15px",
						}}
					/>
				),

				created_at: (
					<Skeleton.Button
						active={true}
						style={{
							height: "15px",
						}}
					/>
				),
				action: (
					<div className="min-w-[150px] text-end -m-2">
						<Skeleton.Button className="inline-block m-2 w-5" />
						<Skeleton.Button className="inline-block m-2 w-5" />
					</div>
				),
			});
		}
	}

	const dataTableColumn = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "File Name",
			dataIndex: "file",
			key: "file",
		},
		{
			title: "Upload By",
			dataIndex: "created_by",
			key: "created_by",
		},

		{
			title: "Date",
			dataIndex: "created_at",
			key: "created_at",
		},

		{
			title: "Actions",
			dataIndex: "action",
			key: "action",
			width: "90px",
		},
	];

	function onChange(pagination, filters, sorter, extra) {
		setState({ ...state, values: { pagination, filters, sorter, extra } });
	}

	return (
		<>
			<Modal
				type={"danger"}
				title="Delete Ad"
				visible={state.deleteModalVisible}
				onOk={handleDeleteFile}
				onCancel={handleCancel}
			>
				<div className="dark:text-white60">
					<p>Are you sure you want to delete this ad?</p>
				</div>
			</Modal>
			<PageHeader
				routes={PageRoutes}
				title="Files"
				className="flex items-center justify-between px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
			/>
			<div className="min-h-[715px] lg:min-h-[580px] flex-1 h-auto px-8 xl:px-[15px] pb-[30px] bg-transparent">
				<GlobalUtilityStyle>
					<Row gutter={15}>
						<Col xs={24} className="mb-[25px]">
							<PaginationStyle>
								<div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
									<div className="py-[16px] px-[25px] text-dark dark:text-white87 font-medium text-[17px] border-regular dark:border-white10 border-b ">
										<Heading
											as="h4"
											className="text-lg font-medium mb-0"
										>
											Files
										</Heading>
									</div>
									<div className="p-[25px]">
										<DataTable
											filterOption
											filterOnchange
											onFilterChange={onMediaFilterChange}
											tableData={tableDataScource}
											columns={dataTableColumn}
											rowSelection={false}
										/>
									</div>
								</div>
							</PaginationStyle>
						</Col>
					</Row>
				</GlobalUtilityStyle>
			</div>
		</>
	);
}

export default DataTables;
