import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
const ManageUser = lazy(() => import('../../container/users/ManageUser'));

const AddUser = lazy(() => import('../../container/users/AddUsers'));
const UserListDataTable = lazy(() => import('../../container/users/UserListDataTable'));
const NotFound = lazy(() => import('../../container/pages/404'));

function PagesRoute() {
  return (
    <Routes>
      <Route path="/*" element={<UserListDataTable />} />
      <Route path="/add-user/*" element={<AddUser />} />
      <Route path=":id/*" element={<ManageUser />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default PagesRoute;
