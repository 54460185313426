import { DataService } from '../../config/dataService/dataService';
import actions from './actions';

const { organizationsDataBegin, organizationsDataSuccess, organizationsDataErr } = actions;

const fetchOrganizationsData = (callback, params) => {
    let url = "/users/organizations";
    if (!params) {
        url = "/users/organizations";
    }
    else {
        url = `/users/organizations`;
    }
    return async (dispatch) => {
        dispatch(organizationsDataBegin());
        try {
            const response = await DataService.get(url);
            if (response.data.errors) {
                dispatch(organizationsDataErr(response.data.errors));
            } else {
                dispatch(organizationsDataSuccess(response.data.data));
                callback();
            }
        } catch (err) {
            dispatch(organizationsDataErr(err));
        }
    };
};



export { fetchOrganizationsData };





