import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";

const { sourcesDataBegin, sourcesDataSuccess, sourcesDataErr } = actions;

const fetchSourcesData = (callback, params) => {
	let url = "/sources/all";
	if (!params) {
		url = "/sources/all";
	} else {
		url = `/sources/media/?type=${params}`;
	}
	return async (dispatch) => {
		dispatch(sourcesDataBegin());
		try {
			const response = await DataService.get(url);
			if (response.data.errors) {
				dispatch(sourcesDataErr(response.data.errors));
			} else {
				dispatch(sourcesDataSuccess(response.data.data));
				callback();
			}
		} catch (err) {
			dispatch(sourcesDataErr(err));
		}
	};
};

const doDeleteFile = async (callback, fileId, onError) => {
	try {
		const deleteFileResposne = await DataService.post("/ads/delete-file", {
			id: fileId,
		});
		callback();
	} catch (err) {
		onError(err);
	}
};

export { fetchSourcesData, doDeleteFile };
