
const actions = {
    FILES_DATA_BEGIN: 'FILES_DATA_BEGIN',
    FILES_DATA_SUCCESS: 'FILES_DATA_SUCCESS',
    FILES_DATA_ERR: 'FILES_DATA_ERR',

    filesDataBegin: () => {
        return {
            type: actions.FILES_DATA_BEGIN,
        };
    },

    filesDataSuccess: (data) => {
        return {
            type: actions.FILES_DATA_SUCCESS,
            data,
        };
    },

    filesDataErr: (err) => {
        return {
            type: actions.FILES_DATA_ERR,
            err,
        };
    },
};

export default actions