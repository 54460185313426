
const actions = {
    ORGANIZATIONS_DATA_BEGIN: 'ORGANIZATIONS_DATA_BEGIN',
    ORGANIZATIONS_DATA_SUCCESS: 'ORGANIZATIONS_DATA_SUCCESS',
    ORGANIZATIONS_DATA_ERR: 'ORGANIZATIONS_DATA_ERR',

    organizationsDataBegin: () => {
        return {
            type: actions.ORGANIZATIONS_DATA_BEGIN,
        };
    },

    organizationsDataSuccess: (data) => {
        return {
            type: actions.ORGANIZATIONS_DATA_SUCCESS,
            data,
        };
    },

    organizationsDataErr: (err) => {
        return {
            type: actions.ORGANIZATIONS_DATA_ERR,
            err,
        };
    },
};

export default actions