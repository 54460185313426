import actions from './actions';

const initialState = {
    data: [],
    loading: false,
    error: null,

};
const { FILES_DATA_BEGIN, FILES_DATA_SUCCESS, FILES_DATA_ERR } = actions;
const filesReducer = (state = initialState, action) => {
    const { type, data, err } = action;
    switch (type) {
        case FILES_DATA_BEGIN:
            return {
                ...state,
                loading: true,
                data: [],
            };
        case FILES_DATA_SUCCESS:
            return {
                ...state,
                data,
                loading: false,
            };
        case FILES_DATA_ERR:
            return {
                ...state,
                error: err,
                loading: false,
            };
        default:
            return state;
    }
};

export { filesReducer };
