import { DataService } from "../../config/dataService/dataService";
import actions from "./actions";

const {
	usersDataBegin,
	usersDataSuccess,
	usersDataErr,
	usersCreateBegin,
	usersCreateRequest,
	usersCreateSuccess,
	usersCreateErr,
	userUpdateRequest,
	userUpdateSuccess,
	userUpdateErr,
} = actions;

const fetchUsersData = (organization, callback = () => {}) => {
	let url = `users/users-by-organization/?organization=${organization}`;
	return async (dispatch) => {
		dispatch(usersDataBegin());
		try {
			const response = await DataService.get(url);
			if (response.data.errors) {
				dispatch(usersDataErr(response.data.errors));
			} else {
				console.log(response.data.data);
				dispatch(usersDataSuccess(response.data.data));
				callback();
			}
		} catch (err) {
			dispatch(usersDataErr(err));
		}
	};
};

const createUser = (values, callback, onError) => {
	return async (dispatch) => {
		dispatch(usersCreateRequest());
		try {
			const response = await DataService.post("/register", values);

			if (response.data.errors) {
				dispatch(usersCreateErr("Registration failed!"));
			} else {
				dispatch(usersCreateErr(false));
				dispatch(usersCreateSuccess(true));
				callback();
			}
		} catch (err) {
			onError(err);
			dispatch(usersCreateErr(err));
		}
	};
};
const updateUser = (values, callback, onError) => {
	return async (dispatch) => {
		dispatch(userUpdateRequest());
		try {
			const response = await DataService.post(
				"/users/update-user",
				values
			);
			if (response.data.errors) {
				dispatch(userUpdateErr("Update failed!"));
			} else {
				dispatch(userUpdateErr(false));
				dispatch(userUpdateSuccess(true));
				callback();
			}
		} catch (err) {
			onError(err);
			dispatch(userUpdateErr(err));
		}
	};
};

const setValues = (values) => {
	return async (dispatch) => {
		dispatch(usersCreateBegin(values));
	};
};

export { fetchUsersData, setValues, createUser, updateUser };
