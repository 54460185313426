
const actions = {
    ADS_DATA_BEGIN: 'ADS_DATA_BEGIN',
    ADS_DATA_SUCCESS: 'ADS_DATA_SUCCESS',
    ADS_DATA_ERR: 'ADS_DATA_ERR',

    adsDataBegin: () => {
        return {
            type: actions.ADS_DATA_BEGIN,
        };
    },

    adsDataSuccess: (data) => {
        return {
            type: actions.ADS_DATA_SUCCESS,
            data,
        };
    },

    adsDataErr: (err) => {
        return {
            type: actions.ADS_DATA_ERR,
            err,
        };
    },
};

export default actions