import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table, message } from 'antd';
import { Link } from 'react-router-dom';
import UilEye from '@iconscout/react-unicons/icons/uil-eye';
import UilEdit from '@iconscout/react-unicons/icons/uil-edit';
import UilTrashAlt from '@iconscout/react-unicons/icons/uil-trash-alt';
import { GlobalUtilityStyle, PaginationStyle } from '../../styled';
import Heading from '../../../components/heading/heading';
import { Skeleton, Button } from 'antd'
import { Modal } from '../../../components/modals/antd-modals';
import { DataService } from '../../../config/dataService/dataService';
import { getItem } from '../../../utility/localStorageControl';

function UserListTable({ usersListData, organization, fetchUsers }) {

  const { loadingState } = useSelector((state) => {
    return {
      loadingState: state.users.loading,
    };
  });

  const usersTableData = [];


  const usersTableColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'first_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Package Name',
      dataIndex: 'package_name',
      key: 'package_name',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
    },

    {
      title: 'Date',
      dataIndex: 'created_at',
      key: 'created_at',
    },

    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      width: '90px',
    },
  ];


  if (usersListData.length > 0) {
    usersListData.map((user) => {
      const { id, last_name, first_name,
        phone_no,
        email,
        package_name,
        expiry_date,
        created_at } = user;

      return usersTableData.push({
        key: id,
        id: <span className="text-body dark:text-white60 text-[15px] font-medium">{`#${id}`}</span>,
        first_name: <span className="text-body dark:text-white60 text-[15px] font-medium">{first_name}</span>,
        last_name: <span className="text-body dark:text-white60 text-[15px] text-center font-medium">{last_name}</span>,
        phone: <span className="text-body dark:text-white60 text-[15px] font-medium">{phone_no}</span>,
        email: <span className="text-body dark:text-white60 text-[15px] font-medium">{email}</span>,
        package_name: <span className="text-body dark:text-white60 text-[15px] font-medium">{package_name}</span>,
        expiry_date: <span className="text-body dark:text-white60 text-[15px] font-medium">{expiry_date}</span>,
        created_at: <span className="text-body dark:text-white60 text-[15px] font-medium text-center">{created_at}</span>,
        // status: (
        //   <span
        //     className={`inline-flex items-center justify-center bg-${status}-transparent text-${status} min-h-[24px] px-3 text-xs font-medium rounded-[15px]`}
        //   >
        //     {status}
        //   </span>
        // ),
        action: (
          <div className="min-w-[150px] text-end -m-2">
            <Link className="inline-block m-2" to={"/admin/users/" + id + "/account" + "?organization=" + organization ?? ""}>
              <UilEye className="w-4 text-light-extra dark:text-white60" />
            </Link>
            <Link className="inline-block m-2" to={"/admin/users/" + id + "/account" + "?editing=true" + "&organization=" + organization ?? ""}>
              <UilEdit className="w-4 text-light-extra dark:text-white60" />
            </Link>
            <Link className="inline-block m-2" to="#" onClick={() => {
              setState({
                ...state,
                deleteModalVisible: true,
                userToDeleteId: id
              })
            }}>
              <UilTrashAlt className="w-4 text-light-extra dark:text-white60" />
            </Link>
          </div>
        ),
      });
    });


  }
  else if (loadingState) {
    for (var i = 0; i < 10; i++) {
      usersTableData.push({
        id: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,

        first_name: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        last_name: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        email: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        phone: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        package_name: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        expiry_date: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        created_at: <Skeleton.Button active={true} style={{
          height: "15px"
        }} />,
        action: (
          <div className="min-w-[150px] text-end -m-2">
            <Skeleton.Button className="inline-block m-2 w-4" />
            <Skeleton.Button className="inline-block m-2 w-4" />
            <Skeleton.Button className="inline-block m-2 w-4" />

          </div>
        ),
      })
    }
  }
  const handleCancel = () => {
    setState({
      ...state,
      deleteModalVisible: false
    })
  }
  const handleUserDelete = async () => {
    setState({
      ...state,
      deleteModalVisible: false,
      userToDeleteId: null,
    })
    const data = {
      user_id: state.userToDeleteId,
      "updated_by": state.admin.full_name,
      "modifier_user_id": state.admin.client_id
    }
    const hide = message.loading('Deleting user...');
    try {
      const response = await DataService.post('/users/delete-user', data)
      message.success("user deleted successfully");
      hide();
      window.location.replace("/admin/users?organization=" + organization ?? "")

    }
    catch (err) {
      let errorMsg = 'An error occured while deleting user'
      if (err.response) {
        errorMsg = err.response.data.message
      }
      hide();
      message.error(errorMsg)
    }

  }

  useEffect(() => {
    setState({
      ...state,
      admin: getItem('user')
    })
  }, [])

  const rowSelection = {
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [state, setState] = useState({
    deleteModalVisible: false,
    userToDeleteId: null,
    admin: {}


  })

  return (
    <>
      <Modal
        type={"danger"}
        title="Delete User"
        visible={state.deleteModalVisible}
        onOk={handleUserDelete}
        onCancel={handleCancel}
      >
        <div className="dark:text-white60">
          <p>Are you sure you want to delete this user ?</p>

        </div>
      </Modal>
      <div className="bg-white dark:bg-white10 p-[25px] rounded-[10px]">
        <GlobalUtilityStyle>
          <PaginationStyle>
            <div className="ant-pagination-custom-style table-responsive hover-tr-none table-th-shape-none table-last-th-text-right table-th-border-none table-head-rounded table-selection-col-pl-25 table-tr-selected-background-transparent table-td-border-none bg-white dark:bg-transparent rounded-[10px] ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-l-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-r-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:first-child]:rounded-none ltr:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-r-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-l-10 rtl:[&>div>div>div>div>div>.ant-table-content>table>thead>tr>th:last-child]:rounded-none">
              <Table
                className="[&>div>div>.ant-table]:mb-7 [&>div>div>.ant-table]:pb-5 [&>div>div>.ant-table]:border-b [&>div>div>.ant-table]:border-regular dark:[&>div>div>.ant-table]:border-white10 ltr:[&>div>div>div>div>div>table>thead>tr>th:first-child]:pl-[20px] ltr:[&>div>div>div>div>div>table>tbody>tr>td:first-child]:pl-[20px] rtl:[&>div>div>div>div>div>table>thead>tr>th:first-child]:pr-[20px] rtl:[&>div>div>div>div>div>table>tbody>tr>td:first-child]:pr-[20px]"
                rowSelection={rowSelection}
                dataSource={usersTableData}
                columns={usersTableColumns}
                pagination={{
                  defaultPageSize: 5,
                  total: usersTableData.length,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  className:
                    '[&>.ant-pagination-total-text]:border-none ssm:[&>.ant-pagination-total-text]:w-full [&>.ant-pagination-total-text]:text-center [&>.ant-pagination-total-text]:text-dark dark:[&>.ant-pagination-total-text]:text-white87 [&>.ant-pagination-total-text]:font-medium [&>.ant-pagination-total-text]:text-[17px md:justify-center',
                }}
              />
            </div>
          </PaginationStyle>
        </GlobalUtilityStyle>
      </div>
    </>

  );
}

export default UserListTable;
