import actions from "./actions";

const {
	CARD_DATA_READ_BEGIN,
	CARD_DATA_READ_SUCCESS,
	CARD_DATA_READ_ERR,
	TOP_MEDIA_DATA_READ_BEGIN,
	TOP_MEDIA_DATA_READ_SUCCESS,
	TOP_MEDIA_DATA_READ_ERR,
	LANGUAGES_DATA_READ_BEGIN,
	LANGUAGES_DATA_READ_SUCCESS,
	LANGUAGES_DATA_READ_ERR,
	BRANDS_COST_DATA_READ_BEGIN,
	BRANDS_COST_DATA_READ_SUCCESS,
	BRANDS_COST_DATA_READ_ERR,
	BRANDS_COST_REGION_DATA_READ_BEGIN,
	BRANDS_COST_REGION_DATA_READ_ERR,
	BRANDS_COST_REGION_DATA_READ_SUCCESS,
	ADS_PER_MONTH_DATA_READ_BEGIN,
	ADS_PER_MONTH_DATA_READ_SUCCESS,
	ADS_PER_MONTH_DATA_READ_ERR,
	SET_ADS_PER_MONTH_DATE,
	SPENDING_DATA_READ_SUCCESS,
	SPENDING_DATA_READ_BEGIN,
	SPENDING_DATA_READ_ERR,
	SET_COMPANIES,
} = actions;

const initialState = {
	cards: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	topMedia: {
		data: null,
		loading: false,
		date: null,

		error: null,
	},
	languages: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	regionalTrendsPie: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	brandsCost: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	brandCostRegional: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	adsPerMonth: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	spending: {
		data: null,
		loading: false,
		error: null,
		date: null,
	},
	companies: null,
};

const dashboardReducer = (state = initialState, action) => {
	//Data = payload
	const { type, data, err, date } = action;
	switch (type) {
		case CARD_DATA_READ_BEGIN:
			return {
				...state,
				cards: {
					...initialState.cards,
					loading: true,
				},
			};
		case CARD_DATA_READ_SUCCESS:
			return {
				...state,
				cards: {
					...initialState.cards,
					data,
					loading: false,
				},
			};
		case CARD_DATA_READ_ERR:
			return {
				...state,
				cards: {
					...initialState.cards,
					loading: false,
					error: err,
				},
			};

		case TOP_MEDIA_DATA_READ_BEGIN:
			return {
				...state,
				topMedia: {
					...initialState.topMedia,
					loading: true,
					data: null,
				},
			};
		case TOP_MEDIA_DATA_READ_SUCCESS:
			return {
				...state,
				topMedia: {
					...initialState.topMedia,
					loading: false,
					data,
				},
			};

		case TOP_MEDIA_DATA_READ_ERR:
			return {
				...state,
				topMedia: {
					...initialState.topMedia,
					loading: false,
					error: err,
				},
			};

		case LANGUAGES_DATA_READ_BEGIN:
			return {
				...state,
				languages: {
					...initialState.languages,
					loading: true,
				},
			};

		case LANGUAGES_DATA_READ_SUCCESS:
			return {
				...state,
				languages: {
					...initialState.languages,
					loading: false,
					data,
					date,
				},
			};

		case LANGUAGES_DATA_READ_ERR:
			return {
				...state,
				languages: {
					...initialState.languages,
					loading: false,
					error: err,
				},
			};

		case "REGIONAL_TRENDS_PIE_DATA_READ_BEGIN":
			return {
				...state,
				regionalTrendsPie: {
					...initialState.regionalTrendsPie,
					loading: true,
				},
			};

		case "REGIONAL_TRENDS_PIE_DATA_READ_SUCCESS":
			return {
				...state,
				regionalTrendsPie: {
					...initialState.regionalTrendsPie,
					date,
					loading: false,
					data,
				},
			};

		case "REGIONAL_TRENDS_PIE_DATA_READ_ERR":
			return {
				...state,
				regionalTrendsPie: {
					...initialState.regionalTrendsPie,
					loading: false,
					error: err,
				},
			};

		case BRANDS_COST_DATA_READ_BEGIN:
			return {
				...state,
				brandsCost: {
					...initialState.brandsCost,
					loading: true,
				},
			};

		case BRANDS_COST_DATA_READ_SUCCESS:
			return {
				...state,
				brandsCost: {
					...initialState.brandsCost,
					loading: false,
					data,
					date,
				},
			};

		case BRANDS_COST_DATA_READ_ERR:
			return {
				...state,
				brandsCost: {
					...initialState.brandsCost,
					loading: false,
					error: err,
				},
			};

		case BRANDS_COST_REGION_DATA_READ_BEGIN:
			return {
				...state,
				brandCostRegional: {
					...initialState.brandCostRegional,
					loading: true,
				},
			};

		case BRANDS_COST_REGION_DATA_READ_SUCCESS:
			return {
				...state,
				brandCostRegional: {
					...initialState.brandCostRegional,
					loading: false,
					data,
				},
			};

		case BRANDS_COST_REGION_DATA_READ_ERR:
			return {
				...state,
				brandCostRegional: {
					...initialState.brandCostRegional,
					loading: false,
					error: err,
				},
			};

		case ADS_PER_MONTH_DATA_READ_BEGIN:
			return {
				...state,
				adsPerMonth: {
					...state.adsPerMonth,
					loading: true,
				},
			};

		case ADS_PER_MONTH_DATA_READ_SUCCESS:
			return {
				...state,
				adsPerMonth: {
					...state.adsPerMonth,
					loading: false,
					data,
					date,
				},
			};

		case ADS_PER_MONTH_DATA_READ_ERR:
			return {
				...state,
				adsPerMonth: {
					...state.adsPerMonth,
					loading: false,
					error: err,
				},
			};

		case SPENDING_DATA_READ_BEGIN:
			return {
				...state,
				spending: {
					...state.spending,
					loading: true,
				},
			};

		case SPENDING_DATA_READ_SUCCESS:
			return {
				...state,
				spending: {
					...state.spending,
					loading: false,
					data,
					date,
				},
			};

		case SPENDING_DATA_READ_ERR:
			return {
				...state,
				spending: {
					...initialState.spending,
					loading: false,
					error: err,
				},
			};

		case SET_ADS_PER_MONTH_DATE:
			return {
				...state,
				adsPerMonth: {
					...state.adsPerMonth,
					loading: false,
					date,
				},
			};

		case SET_COMPANIES:
			return {
				...state,
				companies: data,
			};

		default:
			return state;
	}
};

export default dashboardReducer;
