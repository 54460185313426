import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import Organizations from '../../container/organizations';
import EditOrganizations from '../../container/organizations/EditOrganization';

const NotFound = lazy(() => import('../../container/pages/404'));
const Sources = lazy(() => import('../../container/sources'));

function OrganizationRoutes() {
    return (
        <Routes>
            <Route index element={<Organizations />} />
            <Route path='/:id' element={<EditOrganizations />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default OrganizationRoutes;
