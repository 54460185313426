import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Row, Col, Form, Input, Button, Spin, message } from "antd";
import UilFacebook from "@iconscout/react-unicons/icons/uil-facebook-f";
import UilTwitter from "@iconscout/react-unicons/icons/uil-twitter";
import UilGithub from "@iconscout/react-unicons/icons/uil-github";
import UilCheckCircle from "@iconscout/react-unicons/icons/uil-check-circle";
import { PaystackButton } from "react-paystack";



import { useDispatch, useSelector } from "react-redux";
import { AuthFormWrap } from "./style";
import { Checkbox } from "../../../../components/checkbox/checkbox";
import {
	doSetSigUpValues,
	register,
} from "../../../../redux/authentication/actionCreator";

function PackageSelect() {
	const dispatch = useDispatch();
	const authState = useSelector((state) => state.auth);
	const navigate = useNavigate();

	useEffect(() => {
		console.log(authState);
	}, []);
	const [state, setState] = useState({
		values: null,
		checked: null,
		selectedPackage: null,
	});

	const [amount, setAmount] = useState()

	  // Add a function to calculate the amount based on the selected package
	  const calculateAmount = () => {
		if (state.selectedPackage) {
		  return state.selectedPackage.price * 100; // Convert to kobo or the appropriate currency unit
			setAmount(state.selectedPackage.price * 100)
		}
		return 0; // No package selected
	  };


	// const handleSubmit = (values) => {
	// 	const data = {
	// 		...authState.singUpValues,
	// 		package_name: state.selectedPackage.name,
	// 		package_id: state.selectedPackage.index,
	// 		package_price: state.selectedPackage.price

	// 	};
	// 	dispatch(doSetSigUpValues(data));
	// 	navigate("/keywords-enter");

	// 	console.log("Data is", data);
	// };

	const onChange = (checked) => {
		setState({ ...state, checked });
	};

	const email = authState.singUpValues.email

	const componentProps = {
		email:`${email}`,
		amount: calculateAmount(),
		text: "Pay Now",
		publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
		onSuccess: () =>{
			const data = {
				...authState.singUpValues,
				package_name: state.selectedPackage.name,
				package_id: state.selectedPackage.index,
			};
			dispatch(doSetSigUpValues(data));
			navigate("/keywords-enter");

			console.log("Data is", data);
		},
		onClose: () => alert("Wait! You need this oil, don't go!!!!"),
	  }

	return (
		<>
			{authState.loading && (
				<div className="spin flex items-center justify-center bg-white dark:bg-dark h-screen w-full fixed z-[999] ltr:left-0 rtl:right-0 top-0">
					<Spin />
				</div>
			)}
			<Row justify="center">
				<Col md={13} sm={18} xs={24}>
					<AuthFormWrap className="mt-6 bg-white rounded-md dark:bg-white10 shadow-regular dark:shadow-none">
						<div className="px-5 py-4 text-center border-b border-gray-200 dark:border-white10">
							<h2 className="mb-0 text-xl font-semibold text-dark dark:text-white87">
								Select Package
							</h2>
						</div>
						<div className="px-10 pt-8 pb-6">
							<Form
								name="register"
								// onFinish={handleSubmit}
								layout="vertical"
							>
								<Row
									gutter={5}
									align={"center"}
									justify={"center"}
								>
									<Col xl={6} md={12} xs={24}>
										<div
											className={`rounded border py-5 h-full flex flex-col hover:border-primary cursor-pointer ${
												state.selectedPackage?.index ==
													1 && "border-primary"
											}`}
											onClick={() => {
												setState({
													...state,
													selectedPackage: {
														name: "Individual",
														index: 1,
														price: 99
													},
												});
											}}
										>
											<div className="text-center">
												<h3 className="text-2xl leading-normal font-semibold mb-0">
													Starter
												</h3>
												<h5 className="text-sm font-semibold mb-0">
													$ 99
												</h5>
												<h6 className="text-xs font-semibold text-gray-500">
													per Month
												</h6>
											</div>
											<div className="flex-1"></div>
											<div className="flex flex-col">
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>2 keywords</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3   text-sm items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>2k /month</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														1 user update every 2
														hours
													</span>
												</div>
												<div className="px-3 gap-x-3 py-1   text-sm  items-center border-b">
													<div className="flex  gap-x-3  ">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>Reports</span>
													</div>
													<div className="flex py-1 px-3 pl-5 text-sm gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															1 Email Notification
														</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>PDF Reports</span>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col xl={6} md={12} xs={24}>
										<div
											className={`rounded border py-5 h-full flex flex-col hover:border-primary cursor-pointer ${
												state.selectedPackage?.index ==
													2 && "border-primary"
											}`}
											onClick={() => {
												setState({
													...state,
													selectedPackage: {
														name: "Team",
														index: 2,
														price: 299
													},
												});
											}}
										>
											<div className="text-center">
												<h3 className="text-2xl leading-normal font-semibold mb-0">
													Standard
												</h3>
												<h5 className="text-sm font-semibold mb-0">
													$ 299
												</h5>
												<h6 className="text-xs font-semibold text-gray-500">
													per Month
												</h6>
											</div>
											<div className="flex-1"></div>
											<div className="flex flex-col">
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>5 keywords</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>3 users</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3   text-sm items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														5k mentions/month
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Comparison Section
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Updates every 12 hours
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														2 Month Historical Data
													</span>
												</div>
												<div className="px-3 gap-x-3 py-1   text-sm  items-center border-b">
													<div className="flex  gap-x-3  ">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>Reports</span>
													</div>
													<div className="flex py-1 px-3 pl-5 text-sm gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															2 Email Notification
														</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															Excel Reports
														</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>PDF Reports</span>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col xl={6} md={12} xs={24}>
										<div
											className={`rounded border py-5 h-full flex flex-col hover:border-primary cursor-pointer ${
												state.selectedPackage?.index ==
													3 && "border-primary"
											}`}
											onClick={() => {
												setState({
													...state,
													selectedPackage: {
														name: "Business",
														index: 3,
														price: 599
													},
												});
											}}
										>
											<div className="text-center">
												<h3 className="text-2xl leading-normal font-semibold mb-0">
													Pro
												</h3>
												<h5 className="text-sm font-semibold mb-0">
													$ 599
												</h5>
												<h6 className="text-xs font-semibold text-gray-500">
													per Month
												</h6>
											</div>
											<div className="flex-1"></div>
											<div className="flex flex-col">
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>12 keywords</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>15 users</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3   text-sm items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														10k mentions/month
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Comparison Section
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Updates every 6 hours
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														3 Month Historical Data
													</span>
												</div>
												<div className="px-3 gap-x-3 py-1   text-sm  items-center border-b">
													<div className="flex  gap-x-3  ">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>Reports</span>
													</div>
													<div className="flex py-1 px-3 pl-5 text-sm gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															4 Email Notification
														</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															Infographic Reports
														</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>PDF Reports</span>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col xl={6} md={12} xs={24}>
										<div
											className={`rounded border py-5 h-full flex flex-col hover:border-primary cursor-pointer ${
												state.selectedPackage?.index ==
													4 && "border-primary"
											}`}
											onClick={() => {
												setState({
													...state,
													selectedPackage: {
														name: "Enterprise",
														index: 4,
														price: 999
													},
												});
											}}
										>
											<div className="text-center">
												<h3 className="text-2xl leading-normal font-semibold mb-0">
													Enterprise
												</h3>
												<h5 className="text-sm font-semibold mb-0">
													$ 999
												</h5>
												<h6 className="text-xs font-semibold text-gray-500">
													per Month
												</h6>
											</div>
											<div className="flex-1"></div>
											<div className="flex flex-col">
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>20 keywords</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3  text-sm  items-center border-b">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>Unlimited users</span>
												</div>

												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Comparison Section
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Updates every 2 hours
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Unlimited Historical
														Data
													</span>
												</div>
												<div className="flex py-1 px-3 gap-x-3 text-sm items-starts border-b leading-tight">
													<UilCheckCircle
														color={"#239528"}
														size={16}
													/>
													<span>
														Unlimited Email
														Notification
													</span>
												</div>
												<div className="px-3 gap-x-3 py-1   text-sm  items-center border-b">
													<div className="flex  gap-x-3  ">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>Reports</span>
													</div>

													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															Infographic Reports
														</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3 border-b">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>PDF Reports</span>
													</div>
													<div className="flex py-1 px-3 pl-5  text-sm  gap-x-3">
														<UilCheckCircle
															color={"#239528"}
															size={16}
														/>
														<span>
															Excel Reports
														</span>
													</div>
												</div>
											</div>
										</div>
									</Col>
									<Col xl={12} md={24} xs={24}>
										<Form.Item>
											<PaystackButton
												className="w-full h-12 p-0 mt-6 text-sm font-medium"
												{...componentProps}
												currency="GHS"
											/>
											{/* <Button
												disabled={
													state.selectedPackage ==
													null
												}
												className="w-full h-12 p-0 mt-6 text-sm font-medium"
												htmlType="submit"
												type="primary"
												size="large"
											>
												Continue
											</Button> */}
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
						<div className="p-6 text-center bg-gray-100 dark:bg-white10 rounded-b-md">
							<p className="mb-0 text-sm font-medium text-body dark:text-white60">
								<Link
									to="/"
									className="ltr:ml-1.5 rtl:mr-1.5 text-info hover:text-primary"
								>
									Logout
								</Link>
							</p>
						</div>
					</AuthFormWrap>
				</Col>
			</Row>
		</>
	);
}

export default PackageSelect;
