import { DataService } from '../../config/dataService/dataService';
import actions from './actions';

const { filesDataBegin, filesDataSuccess, filesDataErr } = actions;

const fetchFilesData = (callback, params) => {
    let url = `/ads/files`;
    return async (dispatch) => {
        dispatch(filesDataBegin());
        try {
            const response = await DataService.get(url);
            if (response.data.errors) {
                dispatch(filesDataErr(response.data.errors));
            } else {
                dispatch(filesDataSuccess(response.data.data));
                callback();
            }
        } catch (err) {
            dispatch(filesDataErr(err));
        }
    };
};



export {
    fetchFilesData
};





