import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, message } from 'antd';
import { Button } from '../../components/buttons/buttons';
import Heading from '../../components/heading/heading';
import { GlobalUtilityStyle } from '../styled';
import { getItem } from '../../utility/localStorageControl';
import { updateUser } from '../../redux/users/actionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../components/page-headers/page-headers';
import { useParams } from 'react-router-dom';
import { DataService } from '../../config/dataService/dataService';

function EditOrganizations() {
    const [form] = Form.useForm();

    const [state, setState] = useState({
        values: null,
        organization: null,
        admin: getItem('user'),
    });
    const dispatch = useDispatch();
    const organizationState = useSelector(state => state.organizations)
    const { id } = useParams()
    const organization = organizationState?.data.find(org => org.uid == id);
    const initialValues = {
        client_name: organization?.client_name || "",
        email: organization?.e_mail || "",
        contact: organization?.mobile_phone_1 || "",
    };

    useEffect(() => {
        setState({
            ...state,
            admin: getItem('user'),
        })

    }, [])


    const handleSubmit = async (values) => {
        setState({ ...state, values });
        console.log(state.user)
        const data = {
            uid: organization.uid,
            client_name: values.client_name,
            "e_mail": values.email,
            "mobile_phone_1": values.contact,
            "updated_by": state.admin.full_name,
            "modifier_host_id": state.admin.client_id

        }
        const hide = message.loading("Updating organization")
        try {
            const response = await DataService.post("/users/update-org", data)
            hide()

            message.success(`You have successfully updated your account`);

        }
        catch (err) {
            hide()
            message.error(`An error occurred while updating account`);

        }
    };

    const handleCancel = (e) => {
        e.preventDefault();
        form.resetFields();
    };

    const handleChange = (e) => {
        setState({
            name: e.target.value,
        });
    };
    const PageRoutes = [
        {
            path: '/admin',
            breadcrumbName: 'Dashboard',
        },
        {
            path: '',
            breadcrumbName: 'Manage Organization',
        },
    ];

    return (
        <>

            <PageHeader
                className="flex flex-wrap items-center justify-between px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col sm:justify-center"
                title="Edit Organization"
                routes={PageRoutes}
            />
            <main className="min-h-[715px] lg:min-h-[580px] bg-transparent px-8 xl:px-[15px] pb-[50px] ssm:pb-[30px]">
                <div className="bg-white dark:bg-white10 m-0 p-0 mb-[25px] rounded-10 relative">
                    <div className="py-[18px] px-[25px] text-dark dark:text-white87 font-medium text-[17px] border-regular dark:border-white10 border-b">
                        <Heading as="h4" className="mb-0 text-lg font-medium">
                            Organization info
                        </Heading>
                        <span className="mb-0.5 text-light dark:text-white60 text-13 font-normal">
                            Update organization name,  contact and email
                        </span>
                    </div>
                    <div className="p-[25px] pb-[55px]">
                        <GlobalUtilityStyle>
                            <Row>
                                <Col xs={24}>
                                    <Form form={form} name="editAccount" onFinish={handleSubmit} initialValues={
                                        initialValues
                                    }>
                                        <div className="mb-[26px] pb-[30px] border-b border-regular dark:border-white10">
                                            <Row justify="center">
                                                <Col xxl={10} lg={16} md={18} xs={24}>
                                                    <Form.Item
                                                        label="Name"
                                                        name="client_name"
                                                        initialValue={initialValues.client_name}
                                                        className="mb-[26px] [&>.ant-form-item-row]:flex-col [&>.ant-form-item-row>div]:text-start [&>.ant-form-item-row>div>label]:text-dark dark:[&>.ant-form-item-row>div>label]:text-white87 [&>.ant-form-item-row>div>label]:font-semibold"
                                                    >
                                                        <Input placeholder="Organization Name" />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name="email"
                                                        label="Email Address"
                                                        initialValue={initialValues?.email}

                                                        className="mb-[26px] [&>.ant-form-item-row]:flex-col [&>.ant-form-item-row>div]:text-start [&>.ant-form-item-row>div>label]:text-dark dark:[&>.ant-form-item-row>div>label]:text-white87 [&>.ant-form-item-row>div>label]:font-semibold"
                                                    // rules={[{ required: true, message: 'Please input your email!', type: 'email' }]}
                                                    >
                                                        <Input placeholder="name@example.com" />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name="contact"
                                                        label="Contact"
                                                        initialValue={initialValues?.contact}


                                                        className="mb-[26px] [&>.ant-form-item-row]:flex-col [&>.ant-form-item-row>div]:text-start [&>.ant-form-item-row>div>label]:text-dark dark:[&>.ant-form-item-row>div>label]:text-white87 [&>.ant-form-item-row>div>label]:font-semibold"
                                                    // rules={[{
                                                    //   required: true, type: 'method', validator: (rule, value) => {
                                                    //     var regex = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm;
                                                    //     var phone = value.replaceAll(/\s/g, '').match(regex);
                                                    //     if (!phone) {
                                                    //       return Promise.reject("Phone number must be in the format  +xxx xxx-xxxx");
                                                    //     }
                                                    //     return Promise.resolve();
                                                    //   }
                                                    // }]}
                                                    >
                                                        <Input placeholder="name@example.com" />
                                                    </Form.Item>
                                                    {/* <Form.Item
                        label="Password"
                        name="password"
                        className="mb-[26px] [&>.ant-form-item-row]:flex-col [&>.ant-form-item-row>div]:text-start [&>.ant-form-item-row>div>label]:text-dark dark:[&>.ant-form-item-row>div>label]:text-white87 [&>.ant-form-item-row>div>label]:font-semibold"
                      // rules={[{ required: true, message: 'Please input your password!' }]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item> */}
                                                </Col>
                                            </Row>
                                        </div>

                                        <div className="account-form-bottom">
                                            <Row justify="center">
                                                <Col xxl={10} lg={16} md={18} xs={24}>
                                                    {/* <div className="flex items-center flex-wrap justify-between gap-[15px]">
                        <div>
                          <Heading className="text-base font-semibold text-dark dark:text-white87" as="h4">
                            Close Account
                          </Heading>
                          <p className="mb-0 text-body dark:text-white60">Delete Your Account and Account data</p>
                        </div>
                        <div className="">
                          <Button size="default" type="danger" className="h-[38px] px-4 text-sm font-semibold">
                            Close Account
                          </Button>
                        </div>
                      </div> */}
                                                    <div className="pt-[48px]">
                                                        <Button
                                                            size="default"
                                                            htmlType="submit"
                                                            type="primary"
                                                            className="h-[44px] px-5 text-sm font-semibold"
                                                        >
                                                            Save Change
                                                        </Button>
                                                        &nbsp; &nbsp;
                                                        <Button
                                                            size="default"
                                                            onClick={handleCancel}
                                                            type="light"
                                                            className="h-[44px] px-5 text-sm font-semibold bg-transparent dark:text-white87 dark:border-white10 dark:hover:text-primary dark:hover:border-primary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </GlobalUtilityStyle>
                    </div>
                </div>
            </main>
        </>


    );
}

export default EditOrganizations;
