import React, { lazy } from 'react';
import { Route, Routes } from 'react-router-dom';

const NotFound = lazy(() => import('../../container/pages/404'));
const Comparisons = lazy(() => import('../../container/comparisons'));

function ComparisonsRoutes() {
    return (
        <Routes>
            <Route index element={<Comparisons />} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default ComparisonsRoutes;
