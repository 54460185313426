import React from "react";
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	render,
	PDFDownloadLink,
	PDFViewer,
	Svg,
	Font,
} from "@react-pdf/renderer";
import { PageHeader } from "../components/page-headers/page-headers";
import { GlobalUtilityStyle } from "../container/styled";
import { Icon } from "@iconify/react";
import JostThin from "../static/font/Jost/Jost-Thin.ttf";
import JostExtralight from "../static/font/Jost/Jost-ExtraLight.ttf";
import JostLight from "../static/font/Jost/Jost-Light.ttf";
import JostRegular from "../static/font/Jost/Jost-Regular.ttf";
import JostMedium from "../static/font/Jost/Jost-Medium.ttf";
import JostSemiBold from "../static/font/Jost/Jost-SemiBold.ttf";
import JostBold from "../static/font/Jost/Jost-Bold.ttf";
import JostExtraBold from "../static/font/Jost/Jost-ExtraBold.ttf";
import JostBlack from "../static/font/Jost/Jost-Black.ttf";

Font.register({
	family: "Jost",
	fonts: [
		{ src: JostThin, fontWeight: 100 },
		{ src: JostExtralight, fontWeight: 200 },
		{ src: JostLight, fontWeight: 300 },
		{ src: JostRegular, fontWeight: 400 },
		{ src: JostMedium, fontWeight: 500 },
		{ src: JostSemiBold, fontWeight: 600 },
		{ src: JostBold, fontWeight: 700 },
		{ src: JostExtraBold, fontWeight: 800 },
		{ src: JostBlack, fontWeight: 900 },
	],
});

const styles = StyleSheet.create({
	page: {
		fontFamily: "Jost",
	},
	header: {
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 100,
		padding: 20,
	},
	logo: {
		width: 100,
		height: 50,
	},
	title: {
		fontSize: 24,
		fontWeight: 700,
		color: "#2F80ED",
	},
	subtitle: {
		fontSize: 18,
		fontWeight: 400,
		color: "#333333",
	},
});
const MyDocument = () => (
	<Document title="Ads PDF Report" author="Nixon Analytics">
		<Page orientation="landscape" style={styles.page}>
			<View style={styles.header}>
				<View
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						alignItems: "center",
					}}
				>
					<Image
						style={{
							height: "40px",
							width: "40px",
						}}
						src={require(`../static/img/calender.png`)}
						alt=""
					/>
					<View
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "5px",
						}}
					>
						<Text
							style={{
								fontSize: "18px",
								fontWeight: 500,
							}}
						>
							{" "}
							<Text
								style={{
									fontSize: "16px",
									fontWeight: "normal",
								}}
							>
								from:
							</Text>{" "}
							Mar 11, 2023
						</Text>
						<Text
							style={{
								fontSize: "18px",
								fontWeight: 500,
								marginBottom: "5px",
							}}
						>
							{" "}
							<Text
								style={{
									fontSize: "16px",
									fontWeight: "normal",
								}}
							>
								to:
							</Text>{" "}
							Mar 11, 2023
						</Text>
					</View>
				</View>
				<Image
					style={{
						maxWidth: "120px",
						height: "auto",
					}}
					src={require(`../static/img/logo-dark.png`)}
					alt=""
				/>
			</View>
			<View
				style={{
					textAlign: "center",
					marginBottom: "20px",
				}}
			>
				<Text
					style={{
						fontSize: "35px",
						fontWeight: 700,
					}}
				>
					Document Title
				</Text>
			</View>
			<View
				style={{
					textAlign: "center",
					padding: "15px",
					display: "   flex",
					flexDirection: "column",
					gap: "5px",
					backgroundColor: "#DA255D",
				}}
			>
				<Text
					style={{
						fontSize: "25px",
						fontWeight: 700,
						color: "#fff",
					}}
				>
					Report Type
				</Text>
				<Text
					style={{
						fontSize: "16px",
						color: "#fff",
						fontWeight: 400,
					}}
				>
					Keywords
				</Text>
			</View>
		</Page>
		<Page orientation="landscape" style={styles.page}>
			<View
				style={{
					marginTop: "10px",
					marginBottom: "10px ",
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					gap: "20px",
				}}
			>
				<View
					style={{
						padding: "10px 20px",
						display: "flex",
						flexDirection: "column",
						backgroundColor: "#DA255D",
						width: "300px",
					}}
				>
					<Text
						style={{
							fontSize: "20px",
							fontWeight: 600,
							color: "#fff",
						}}
					>
						Title
					</Text>
					<Text
						style={{
							fontSize: "16px",
							color: "#fff",
							fontWeight: 400,
						}}
					>
						Description
					</Text>
				</View>
				<Text
					style={{
						fontSize: "14px",
						fontWeight: 500,
						color: "#808080",
					}}
				>
					Extra text
				</Text>
			</View>
		</Page>
	</Document>
);
function Test() {
	const testRef = React.useRef(null);
	return (
		<>
			<PageHeader
				// routes={PageRoutes}
				title="Generate PDF Report"
				className="flex items-center justify-between px-8 xl:px-[15px] pt-2 pb-6 sm:pb-[30px] bg-transparent sm:flex-col"
			/>

			<>
				<GlobalUtilityStyle>
					<main className="min-h-[715px] lg:min-h-[580px] flex-1 h-auto px-8 xl:px-[15px] pb-[30px] bg-transparent overflow-hidden">
						<PDFViewer height={"800px"} className="w-full h-96">
							<MyDocument />
						</PDFViewer>
						<div
							className="bg-red-500"
							ref={testRef}
							onClick={() => {
								console.log(testRef.current);
							}}
						>
							Click me
						</div>
					</main>
				</GlobalUtilityStyle>
			</>
		</>
	);
}

export default Test;
