import { lang } from "moment";

const actions = {
  CARD_DATA_READ_BEGIN: 'CARD_DATA_READ_BEGIN',
  CARD_DATA_READ_SUCCESS: 'CARD_DATA_READ_SUCCESS',
  CARD_DATA_READ_ERR: 'CARD_DATA_READ_ERR',
  TOP_MEDIA_DATA_READ_BEGIN: 'TOP_MEDIA_DATA_READ_BEGIN',
  TOP_MEDIA_DATA_READ_SUCCESS: 'TOP_MEDIA_DATA_READ_SUCCESS',
  TOP_MEDIA_DATA_READ_ERR: 'TOP_MEDIA_DATA_READ_ERR',
  LANGUAGES_DATA_READ_BEGIN: 'LANGUAGES_DATA_READ_BEGIN',
  LANGUAGES_DATA_READ_SUCCESS: 'LANGUAGES_DATA_READ_SUCCESS',
  LANGUAGES_DATA_READ_ERR: 'LANGUAGES_DATA_READ_ERR',
  REGIONAL_TRENDS_PIE_DATA_READ_BEGIN: 'REGIONAL_TRENDS_PIE_DATA_READ_BEGIN',
  REGIONAL_TRENDS_PIE_DATA_READ_SUCCESS: 'REGIONAL_TRENDS_PIE_DATA_READ_SUCCESS',
  REGIONAL_TRENDS_PIE_DATA_READ_ERR: 'REGIONAL_TRENDS_PIE_DATA_READ_ERR',
  BRANDS_COST_DATA_READ_BEGIN: 'BRANDS_COST_DATA_READ_BEGIN',
  BRANDS_COST_DATA_READ_SUCCESS: 'BRANDS_COST_DATA_READ_SUCCESS',
  BRANDS_COST_DATA_READ_ERR: 'BRANDS_COST_DATA_READ_ERR',
  BRANDS_COST_REGION_DATA_READ_BEGIN: 'BRANDS_COST_REGION_DATA_READ_BEGIN',
  BRANDS_COST_REGION_DATA_READ_ERR: 'BRANDS_COST_REGION_DATA_READ_ERR',
  BRANDS_COST_REGION_DATA_READ_SUCCESS: 'BRANDS_COST_REGION_DATA_READ_SUCCESS',
  ADS_PER_MONTH_DATA_READ_BEGIN: 'ADS_PER_MONTH_DATA_READ_BEGIN',
  ADS_PER_MONTH_DATA_READ_SUCCESS: 'ADS_PER_MONTH_DATA_READ_SUCCESS',
  ADS_PER_MONTH_DATA_READ_ERR: 'ADS_PER_MONTH_DATA_READ_ERR',
  SPENDING_DATA_READ_BEGIN: 'SPENDING_DATA_READ_BEGIN',
  SPENDING_DATA_READ_SUCCESS: 'SPENDING_DATA_READ_SUCCESS',
  SPENDING_DATA_READ_ERR: 'SPENDING_DATA_READ_ERR',
  SET_ADS_PER_MONTH_DATE: 'SET_ADS_PER_MONTH_DATE',
  SET_COMPANIES: 'SET_COMPANIES',

  cardDataReadBegin: () => {
    return {
      type: actions.CARD_DATA_READ_BEGIN,
    };
  },

  cardDataReadSuccess: (data) => {
    return {
      type: actions.CARD_DATA_READ_SUCCESS,
      data,
    };
  },

  cardDataReadErr: (err) => {
    return {
      type: actions.CARD_DATA_READ_ERR,
      err,
    };
  },

  topMediaDataReadBegin: () => {
    return {
      type: actions.TOP_MEDIA_DATA_READ_BEGIN,
    };
  },

  topMediaDataReadSuccess: (data) => {
    return {
      type: actions.TOP_MEDIA_DATA_READ_SUCCESS,
      data,
    };
  },

  topMediaDataReadErr: (err) => {
    return {
      type: actions.TOP_MEDIA_DATA_READ_ERR,
      err,
    };
  },

  languagesDataReadBegin: () => {
    return {
      type: actions.LANGUAGES_DATA_READ_BEGIN,
    };
  },
  languagesDataReadSuccess: (data, date) => {
    return {
      type: actions.LANGUAGES_DATA_READ_SUCCESS,
      data,
      date
    };
  },
  languagesDataReadErr: (err) => {
    return {
      type: actions.LANGUAGES_DATA_READ_ERR,
      err,
    };
  },
  regionalTrendsPieDataReadBegin: () => {
    return {
      type: actions.REGIONAL_TRENDS_PIE_DATA_READ_BEGIN,
    };
  },
  regionalTrendsPieDataReadSuccess: (data, date) => {
    return {
      type: actions.REGIONAL_TRENDS_PIE_DATA_READ_SUCCESS,
      data,
      date
    };
  },
  regionalTrendsPieDataReadErr: (err) => {
    return {
      type: actions.REGIONAL_TRENDS_PIE_DATA_READ_ERR,
      err,
    };
  },
  brandsCostDataReadBegin: () => {
    return {
      type: actions.BRANDS_COST_DATA_READ_BEGIN,
    };
  },
  brandsCostDataReadSuccess: (data, date) => {
    return {
      type: actions.BRANDS_COST_DATA_READ_SUCCESS,
      data,
      date
    };
  },
  brandsCostDataReadErr: (err) => {
    return {
      type: actions.BRANDS_COST_DATA_READ_ERR,
      err,
    };
  },

  brandsCostRegionalDataReadBegin: () => {
    return {
      type: actions.BRANDS_COST_REGION_DATA_READ_BEGIN,
    };
  },
  brandsCostRegionalDataReadSuccess: (data) => {
    return {
      type: actions.BRANDS_COST_REGION_DATA_READ_SUCCESS,
      data,
    };
  },
  brandsCostRegionalDataReadErr: (err) => {
    return {
      type: actions.BRANDS_COST_REGION_DATA_READ_ERR,
      err,
    };
  },

  adsPerMonthDataReadBegin: () => {
    return {
      type: actions.ADS_PER_MONTH_DATA_READ_BEGIN,
    };
  },
  adsPerMonthDataReadSuccess: (data, date) => {
    return {
      type: actions.ADS_PER_MONTH_DATA_READ_SUCCESS,
      data,
      date
    };
  },
  adsPerMonthDataReadErr: (err) => {
    return {
      type: actions.ADS_PER_MONTH_DATA_READ_ERR,
      err,
    };
  },
  setAdsPerMonthDateFilter: (date) => {
    return {
      type: actions.SET_ADS_PER_MONTH_DATE,
      data: date
    };
  },

  spendingDataReadBegin: () => {
    return {
      type: actions.SPENDING_DATA_READ_BEGIN,
    };
  },
  spendingDataReadSuccess: (data, date) => {
    return {
      type: actions.SPENDING_DATA_READ_SUCCESS,
      data,
      date
    };
  },
  spendingDataReadErr: (err) => {
    return {
      type: actions.SPENDING_DATA_READ_ERR,
      err,
    };
  },
  setCompanies: (companies) => {
    return {
      type: actions.SET_COMPANIES,
      data: companies
    };
  }



};

export default actions;
